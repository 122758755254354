.item-price{
  font-size: $item-price-size;
  color: $item-price-color;
  line-height: $item-price-height;
  margin: $item-price-head-space $item-price-side-space $item-price-foot-space;
} //item-price

.item-image{
  border: $item-image-border-type $item-image-border-width $item-image-border-color;
  width: $item-image-width;
  float: $item-image-float;
  padding-top: $item-image-head-space;
  padding-bottom: $item-image-foot-space;

  img{
    margin: $center-margin;
    display: block;
  } //img
} //item-image

.item-content{
  font-size: $item-content-size;
  color: $item-content-color;
  padding: $item-content-padding-v $item-content-padding-h;

  p{
    margin-bottom: $item-content-para-space;
  }
}
