.header-h1{
  margin: 0;
  padding: 0;
} //header-h1

.main-banner{
  position: relative;

  @include respond-to('small'){
    max-height: 60vh !important;
  }

  img{
    @include respond-to('small'){
      max-width: unset;
      height: 60vh;
    }
  }

  @at-root #{&}__single{
    position: relative;
  } //__single
  @at-root #{&}__texts{
    position: absolute;
    float:$right;
    top: $banner-texts-top;
    bottom: $banner-texts-bottom;
    margin-#{$right}: $banner-texts-right;
    width: $max-width * $banner-texts-width-percent;

    @include respond-to('medium'){
      width: 90%;
      top: 80px;
    }
  } //__texts
  @at-root #{&}__title{
    font-size: $banner-title-size;
    font-weight: $banner-title-weight;
    line-height: $banner-title-height;
    letter-spacing: $banner-title-spacing;

    @include respond-to('medium'){
      font-size: 25px;
      line-height: 30px;
      margin-bottom: 10px;
    }
  } //__title
  @at-root #{&}__sub{
    font-size: $banner-sub-size;
    font-weight: $banner-sub-weight;
    line-height: $banner-sub-height;
    margin-bottom: $banner-sub-bottom-space;

    @include respond-to('medium'){
      font-size: 18px;
      line-height: 25px;
      margin-bottom: 15px;
    }
  } //__sub
} //main-banner

.box{
  width: 100%;
  padding-top: $box-head-space;
  padding-bottom: $box-foot-space;
  position: relative;

  @at-root #{&}__strip{
    height: $box-strip-width;
    margin-top: $box-strip-top;

    @at-root #{&}_silver{
      background-color: $color-silver;
    } //_silver
  } //__strip

  @at-root #{&}__title{
    text-align: center;
    position: relative;
    font-size: $box-title-size;

    @at-root #{&}_strong{
      font-weight: 700;
    }
  } //__title

  @at-root #{&}__sub-title{
    font-size: $box-subtitle-size;
    text-align: center;
    position: relative;
  } //__sub-title

  @at-root #{&}__content{
    font-size: $box-content-size;
    line-height: $box-content-line;
    position: relative;

    p{
      font-size: inherit;
      margin-bottom: $box-content-p-bottom;
    } //p
    @at-root #{&}_block{
      @at-root #{&}_s{
        width: $box-content-block-small;
        min-width: $box-content-block-small-min;
      } //_s
      @at-root #{&}_m{
        width: $box-content-block-medium;
        min-width: $box-content-block-medium-min;
      } //_m
      @at-root #{&}_l{
        width: $box-content-block-large;
        min-width: $box-content-block-large-min;
      } //_l
      @at-root #{&}_full{
        width: 100%;
      } //_l
    } //_block
  } //__content

  @at-root #{&}__img{
    @at-root #{&}_height{
      @at-root #{&}_full{
        max-width: unset;
        width: auto;
        height: 100%;
        position: absolute;
        top: 0;
      } //_full
    } //_height
  } //__img

  @at-root #{&}__background{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    #{$left}: 0;
    z-index: -1;

    @at-root #{&}_color{
      @at-root #{&}_black{
        background-color: $color-black;
      } //_black
      @at-root #{&}_white{
        background-color: $color-white;
      } //_white
    } //_color
    @at-root #{&}_opacity{
      @at-root #{&}_half{
        opacity: .5;
      } //_half
      @at-root #{&}_third{
        opacity: .33;
      } //_half
    } //_opacity
  } //__background

  @at-root #{&}_bg{
    @at-root #{&}_white{
      background-color: $color-white;
    } //_white
    @at-root #{&}_silver{
      background-color: $color-silver;
    } //_silver
    @at-root #{&}_deep-blue{
      background-color: $color-deep-blue;
    } //_deep-blue
    @at-root #{&}_water-blue{
      background-color: $color-water-blue;
    } //_deep-blue
    @at-root #{&}_navy-blue{
      background-color: $color-navy-blue;
    } //_deep-blue
    @at-root #{&}_img{
      background-image: url($box-bg-img);
    } //_img
  } //_bg

  @at-root #{&}_border{
    @at-root #{&}_grey{
      border: solid $box-border-width $color-grey;
    } //_grey
  } //_border

  @at-root #{&}_align{
    @at-root #{&}_center{
      text-align: center;
    } //_center
  } //_align
} //box

.buttons-container{
  display: block;
  position: relative;
  text-align: center;
  margin-#{$left}: $button-spacing * -1;
  margin-#{$right}: $button-spacing * -1;
  padding-top: $button-head-space;

  @at-root #{&}__button{
    display: inline-block;
    margin: 0 $button-spacing / 2;
    width: $button-width;
  } //button
  @at-root #{&}__icon{
    display: block;
    margin: $center-margin;
  } //icon
  @at-root #{&}__title{

  } //title

  @at-root #{&}_padding-top{
    @at-root #{&}_small{
      padding-top: $buttons-container-head-space;
    } //_small
  } //_margin-top

  @at-root #{&}_padding-bottom{
    @at-root #{&}_small{
      padding-bottom: $buttons-container-foot-space;
    } //_small
  } //_padding-bottom

  @at-root #{&}_bg-color{
    @at-root #{&}_silver{
      background-color: $color-silver;
    } //_silver
  } //_bg-color
} //buttons-container

.slider-container{
  text-align: center;
  position: relative;
  margin-top: $slider-head-space;

  @at-root #{&}__item{
    padding: $slider-item-head-space $padding-base $slider-item-foot-space;
    position: relative;

    @at-root #{&}_size{
      @at-root #{&}_m{
        width: $slider-item-width;
        height: $slider-item-height;
      } //_m
    } //_size

    @at-root #{&}_onsale{
      &:after{
        content: url(/assets/images/onsale.png);
        position: absolute;
        top: 0;
        #{$left}: 0;
      } //after
    } //_onsale
  } //__item
  @at-root #{&}__image{
    display: block;
    margin: $center-margin;
  } //image
  @at-root #{&}__text{
    position: absolute;
    bottom: $slider-item-foot-space;
    width: 100%;
    margin-#{$left}: $padding-base * -1;
    margin-#{$right}: $padding-base * -1;
  } //__text
  @at-root #{&}__title{
    font-size: $slider-title-size;
    color: $slider-title-color;
    position: relative;

  } //__title
  @at-root #{&}__price{
    position: relative;
    font-size: $slider-price-size;
    color: $slider-price-color;
  } //__price
  @at-root #{&}__overlay{
    position: absolute;
    top: 0;
    #{$left}: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: 0;
    transition: .2s;

    &:hover{
      opacity: 1;
    } //hover
  } //__overlay

  @at-root #{&}__full-transparent{
    position: absolute;
    top: 0;
    #{$left}: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: $slider-hover-opacity;
    transition: .2s;
  } //__full-transparent
} //slider-container
