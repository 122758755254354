body.scrolling .header-desktop{
  height: 70px;

  .header-bg{
    transform: translateY(-100%);
    opacity: 1;
  }

  .header-bg-scroll{
    transform: translateY(0);
    //opacity: 1;
  }

  .header-logo__image{
    max-height: 60px;
  }
}

.main-header{
  width: 100%;
  position: fixed;
  #{$left}: 0;
  top: 0;
  z-index: 100;
}

.header-bg{
  width: 100%;
  height: 100%;
  background-image: url(../images/header_bg.png);
  top: 0;
  #{$left}: 0;
  position: absolute;
  pointer-events: none;
  z-index: -1;
  transition: .7s;
  transform: translateY(0%);
}

.header-bg-scroll{
  width: 100%;
  height: 70px;
  background-color: #000;
  top: 0;
  #{$left}: 0;
  position: absolute;
  pointer-events: none;
  z-index: -1;
  transition: .7s;
  //opacity: 0;
  transform: translateY(-100%);
}

.header-desktop{
  height: 180px;
  @include respond-to('medium'){
    display: none;
  }
} //header-desktop

.header-wrapper{
  max-width: $max-width;
  padding: $padding-base;
} //header-wrapper

.header-logo{
  padding-top: 15px;
  transition: .2s;
} //header-logo__image

.header-logo__image{
  max-height: 100%;
  transition: .2s;
  height: auto;
} //header-logo__image

.header-main{
  @at-root #{&}__phone{
    font-size: $header-font-size-medium;
    text-align:$right;
    position: relative;

    &:after{
      content: url(../images/header_phone.png);
      position: absolute;
      #{$right}: 0;
      top: 0;
      transform: translate(100%, 20%);
    }
  } //header-main__phone

  @at-root #{&}_color{
    @at-root #{&}_blue{
      color: $header-secondary-color;
    } //blue
  } //color
} //header-main



.header-main__secondary-menu{
  padding-#{$left}: $header-sec-spacing-l;
} //header-main__secondary-menu

.header-main__secondary-item{
  display: inline-block;
  font-size: $header-font-size-small;

  a{
    color: inherit;
    font-size: inherit;
  }
} //header-main__secondary-item

.header-main__item{

} //header-main__item

.header-main__item_v-separator{
  &:after{
    content: '|';
    margin: $header-sec-separator-margin;
  }
} //header-main__item_v-separator

.header-main_main-color{
  color: $header-font-color;
} //header-main_main-color

//Header for mobile devices
.header-mobile{
  display: none;
  @include respond-to('medium'){
    display: block;
  }
  .phone-icn{
    color: #fff;
    font-size: 30px;
    float: $right;
    position: relative;
    top: 10px;
    #{$right}: 5px;
  }
}

.hamburger-box{
  margin: 15px;
}

.header-mobile-logo{
  img{
    max-height: 50px;
    margin: 5px;
  }
}
