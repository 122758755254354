.home-strip{
  background-color: rgba(255,255,255,.75);
  border-radius: 5px;
  padding: 5px 10px;
  position: relative;
  top: -35px;

  @include respond-to('medium'){
    position: fixed;
    bottom: 0;
    left: 0;
  }
}

.mini-menu{
  padding: 10px 0;
  li{
    display: inline-block;
    position: relative;
    padding-#{$left}: 35px;
    height: 100%;

    span{
      position: relative;
      top: 20px;
      padding-#{$right}: 10px;
    }
  }
}

.project-menu-offset{
  top: 0px;
  padding: 15px !important;

  @at-root #{&}_30{
    top: -30px;
    #{$right}: 0;
    padding: 5px!important;
    position: absolute !important;
  }
}

.project-gradient-btn{
  @at-root #{&}_grey{
    background: linear-gradient(to bottom, #fff, #ddd);
    padding-top: $j-btn-top-padding - 5px;
    padding-bottom: $j-btn-bottom-padding -5px;

    &:active{
      padding-top: $j-btn-top-padding - 4px;
      padding-bottom: $j-btn-bottom-padding -6px;
    }

    top: -8px;
  } //_grey
  @at-root #{&}_blue{
    background: linear-gradient(to bottom, #00b1fa, #0882b5);
  } //_blue
}

.project-white-border{
  border-width: 2px 1px;
  border-color: #fff;
  border-style: solid;
}

.project-gallery{
  padding-#{$right}: 9%;
  padding-top: 30px;
  padding-bottom: 22px;
  height: 580px;

  @include respond-to('small'){
    height: unset;
  }

  &>span{
    padding-bottom: 30px;
    display: block;
  }
}

.project-contact{
  padding-#{$left}: 9%;
  padding-top: 30px;
  padding-bottom: 22px;
  height: 580px;

  &>span{
    padding-bottom: 30px;
    display: block;
  }
}

.section-limit-1030{
  max-width: 1030px;
  margin: 0 auto;
}

.page-header-banner{
  background-position: 0 0;
  background-attachment: fixed;
  background-repeat: no-repeat;
  height: $general-header-height;
}

.breadcrumb{
  margin-top: 10px;
  padding-bottom: 0;
}

.project-item-images{
  height: 600px;
  position: relative;

} //project-item-images

.project-item-thumbs{
  li{
    border: solid 1.5px #fff;
    cursor: pointer;
    width: 162px;
    height: 100px;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    img{
      display: none;
    }
  }
} //project-item-thumbs

.project-item-page-image{
  height: 600px;
  width: calc(100% - 183px);
  position: absolute;
  top: 0px;
  #{$left}: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @include respond-to('small'){
    height: 350px;
    width: 100%;
    position: static;
  }

  .lightgallery-img{
    height: 100%;
  }

  img{
    opacity: 0;
    display: none;
    display: block;
    //display: none;
    width: 100%;
    height: 100%;
  } //img
}

.project-item-image{
  /*height: 600px;
  width: calc(100% - 183px);
  position: absolute;
  top: 0px;
  #{$left}: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;*/
  position: relative;
  margin: 0 auto;
  width: 50%;
  text-align: center;
  #{$left}: 0;

  img{
    /*opacity: 0;
    display: block;
    width: 100%;
    height: 100%;*/
  } //img
} //project-item-image

.title-custom-margin{
  margin: 10px 0 30px;
}

.content-image{
  max-width: 60%;
  float:$left;
  margin-#{$right}: 20px;
  margin-bottom: 10px;
}

.project-padding-bottom-none{
  padding-bottom: 0;
}

.gallery-cat-img{
  height: 400px;
  display: block;
}

.project-item-thumbs-container{
  height: 600px;
  overflow-y: scroll;
  width: 183px;
  position: relative;
  z-index: 1;
  @include respond-to('small'){
    height: 160px;
    width: 100%;
    overflow-x: scroll;
    overflow-y: initial;
    margin-bottom: 20px;
    & > ul{
      float: none !important;
      & > li{
        float: right;
        width: 120px;
        height: 80px;
      }
    }
  }
} //project-item-thumbs-container
