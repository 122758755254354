.category-banner{
  position: relative;
  max-height: 440px;
  background-position-y: -20px;

  @at-root #{&}_fixed{
    background-attachment: fixed;
  } //_fixed

  @include respond-to('medium'){
    height: 50vh;
    background-size: cover;
    background-position: center;
  }
} //category-banner

.category-titles{
  @at-root #{&}__main{
    font-size: $category-title-size;
    color: $category-title-color;
    margin-bottom: $category-title-foot-spacing;
  } //__main
  @at-root #{&}__summary{
    font-size: $category-summary-size;
    line-height: $category-summary-height;
    color: $category-summary-color;
  } //__summary
  @at-root #{&}_center{
    text-align: center;
  } //_center
} //category-titles
