//main banner box variables
$banner-texts-top: 33%;
$banner-texts-bottom: unset;
$banner-texts-right: unset;
$banner-texts-width-percent: .3845;

$banner-title-size: 35px;
$banner-title-weight: 800;
$banner-title-spacing: unset;
$banner-title-height: 34px;

$banner-sub-size: 25px;
$banner-sub-weight: Regular;
$banner-sub-height: 34px;
$banner-sub-bottom-space: 20px;

//box variables
$box-title-size: 50px;
$box-subtitle-size: 20px;
$box-content-size: $font-size-base;

$box-content-block-small: 30%;
$box-content-block-small-min: 250px;
$box-content-block-medium: 50%;
$box-content-block-medium-min: 400px;
$box-content-block-large: 70%;
$box-content-block-large-min: 1000px;
$box-content-line: 25px;
$box-content-p-bottom: 25px;

$box-strip-width: 2px;
$box-strip-top: 35px;

$box-head-space: 30px;
$box-foot-space: 30px;

$box-border-width: 1px;

$button-spacing: 68px;
$button-width: 80px;
$button-head-space: 75px;

$box-bg-img: '/assets/images/box_bg.jpg';

//slider items variables
$slider-head-space: 60px;

$slider-item-width: 310px;
$slider-item-height: 400px;
$slider-item-head-space: 35px;
$slider-item-foot-space: 25px;

$slider-title-size: 25px;
$slider-title-color: $color-black;

$slider-price-size: 20px;
$slider-price-color: $color-light-blue;

$slider-hover-opacity: .72;
