.j-footer{
  width: 100%;
  position: relative;
  bottom: 0;
  min-height: $footer-min-height;
  background-color: $footer-bg-color;
  padding-top: $footer-padding ;

} //j-footer

.footer-links{
  ul{
    li{
      &:first-of-type{
        font-size: $footer-title-size;
        color: $footer-title-color;
        padding-bottom: 8px;
      } //1st

      font-size: $footer-link-size;
      color: $footer-link-color;
      line-height: $footer-link-height;

      a{
        font-size: inherit;
        color: inherit;

        &:hover{
          opacity: .8;
        }
      } //a
    } //li
  } //ul
} //footer-links

.footer-title{
  font-size: $footer-title-size;
  color: $footer-title-color;
}

.social-list{
  margin-top: 8px;

  li{
    display: inline-block;

    a{
      i{
        font-size: $social-size;
        color: $social-color;
        opacity: .8;
        margin-#{$left}: $social-spacing;

        &:hover{
          opacity: 1;
        }
      } //i
    } //a
  } //li
} //social-list

.footer-logo{
  text-align:$left;
}

.footer-address{
  color: $footer-adress-color;
  font-size: $footer-address-size;
}

.footer-bottom{
  bottom: 0;
  position: absolute;
  width: $max-width;
  margin-#{$left}: -15px;
  margin-#{$right}: -15px;
  padding-bottom: $footer-padding;
}
