.text{
  a{
    color: inherit;
  }
  @at-root #{&}__title{
    font-size: $title-font-size;
    color: $title-color;
    padding: $title-padding-v $title-padding-h;
  }
  @at-root #{&}_space{
    letter-spacing: $text-space-letters;
    line-height: $text-space-lines;

    @include respond-to('medium'){
      line-height: 20px;
    } //---medium

    p{
      margin-top: $text-space-para / 2;
      margin-bottom: $text-space-para / 2;

      &:last-of-type{
        margin-bottom: $text-space-para;
      }
    }
  }
  @at-root #{&}_include-p{
    p{
      color: inherit;
      font-size: inherit;
    }
  }

  @at-root #{&}_strong{
    font-weight: 700;
  } //_strong
  @at-root #{&}_size{
    @at-root #{&}_s{
      font-size: $font-size-s !important;
    } //_s
    @at-root #{&}_m{
      font-size: $font-size-m !important;
      @include respond-to('medium'){
        font-size: 16px !important;
      } //---medium
    } //_m
    @at-root #{&}_lg{
      font-size: $font-size-lg !important;
    } //_lg
    @at-root #{&}_xl{
      font-size: $font-size-xl !important;
    } //_xl
  } //_size
  @at-root #{&}_color{
    @at-root #{&}_black{
      color: $color-black !important;
    } //_black
    @at-root #{&}_white{
      color: $color-white !important;
    } //_white
    @at-root #{&}_steel-grey{
      color: $color-steel-grey;
    } //_steel-grey
    @at-root #{&}_silver{
      color: $color-silver;
    } //_silver
    @at-root #{&}_grey{
      color: $color-grey;
    } //grey
  } //_color
  @at-root #{&}_align{
    @at-root #{&}_center{
      text-align: center;
    } //_center
  } //_align
  @at-root #{&}_shadow{
    text-shadow:  $text-shadow-offset-x
                  $text-shadow-offset-y
                  $text-shadow-size
                  $text-shadow-color;
  } //_shadow
} //text

.component{
  &_hide{
    &_medium{
      @include respond-to('medium'){
        display: none;
      }
    }
  }
  @at-root #{&}_space{
    @at-root #{&}_vertical{
      padding-top: $component-space-top !important;
      padding-bottom: $component-space-bottom !important;
    } //_vertical
  } //_space
  @at-root #{&}_pull-down{
    bottom: 0 !important;
  } //_pull-down
  @at-root #{&}_margin{
    @at-root #{&}_none{
      margin: 0 !important;
    } //_none
  } //_margin
  @at-root #{&}_width{
    @at-root #{&}_full{
      width: 100% !important;
    } //_full
  } //_width
  @at-root #{&}_center{
    margin: $center-margin;
    display: block;

    @at-root #{&}_v{
      top: 50%;
      transform: translateY(-50%);
      position: absolute !important;
    } //_v
    @at-root #{&}_ab{
      left: 50%;
      transform: translateX(-50%);
      position: absolute !important;
    } //_ab
    @at-root #{&}_full{
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute !important;
    } //_v
  } //_center
  @at-root #{&}_shadow{
    box-shadow: $component-shadow-offset-x
                $component-shadow-offset-y
                $component-shadow-size
                $component-shadow-spread
                $component-shadow-color;
  } //_shadow
  @at-root #{&}_to{
    @at-root #{&}_left{
      #{$left}: 0 + $padding-base;
      position: absolute;
    } //_left
    @at-root #{&}_right{
      #{$right}: 0 + $padding-base;
      position: absolute;
    } //_right
    @at-root #{&}_top{
      top: 0 + $padding-base;
      position: absolute;
    } //_top
    @at-root #{&}_bottom{
      bottom: 0 + $padding-base;
      position: absolute;
    } //_bottom
  } //_to
  @at-root #{&}_no-padding{
    padding: 0 !important;
    @at-root #{&}_medium{
      @include respond-to('medium'){
        padding: 0 15px !important;
      }
    }
  } //_no-padding
  @at-root #{&}_hover{
    @at-root #{&}_above{
      position: absolute !important;
      top: $component-hover-distance;
      transform: translateY(-100%);
      @at-root #{&}_gallery{
        top: unset !important;
        bottom: 30px;
        height: 55px !important;
      }
    } //_above
    @at-root #{&}_above-center{
      position: absolute;
      top: $component-hover-distance;
      left: 50%;
      transform: translate(-50%, -100%);
    } //_above
  } //_hover
  @at-root #{&}_no-margin{
    margin: 0;
  } //_no-margin
} //component

.general-header-bg{
  position: fixed;
  width: 100%;
  top: 0;
  #{$left}: 0;
  background-color: unset;
  height: $general-header-height;
} //general-header-bg

.j-btn{
  display: inline-block;
  position: relative;
  z-index: 10;
  font-size: $j-btn-text-size;
  border-radius: $j-btn-radius;
  padding: $j-btn-top-padding $j-btn-h-padding $j-btn-bottom-padding;
  filter: none;
  transition: .3s;
  border: none;

  &:hover{
    filter: brightness(110%);
    box-shadow: 2px 2px 4px rgba(0,0,0,0.3);
  }
  &:active{
    box-shadow: 0 0 2px rgba(0,0,0,.7);
    padding-top: $j-btn-top-padding + 1px;
    padding-#{$left}: $j-btn-h-padding + 1px;
    padding-bottom: $j-btn-bottom-padding - 1px;
    padding-#{$right}: $j-btn-h-padding - 1px;
    //top: 1px;
    //#{$left}: 1px;
  }

  @at-root #{&}_color{
    @at-root #{&}_blood-red{
      background-color: $color-blood-red;
    } //_light-blue
    @at-root #{&}_light-blue{
      background-color: $color-light-blue;
    } //_light-blue
    @at-root #{&}_deep-blue{
      background-color: $color-deep-blue;
    } //_deep-blue
    @at-root #{&}_orange{
      background-color: $color-orange;
    } //_deep-blue
    @at-root #{&}_white{
      background-color: $color-white;
    } //_white
    @at-root #{&}_black{
      background-color: $color-black;
    } //_white
    @at-root #{&}_lime{
      background-color: $color-lime;
    } //_white
  } //_color

  @at-root #{&}_text{
    @at-root #{&}_white{
      color: $color-white !important;

      &:hover{
        color: $color-white !important;
      } //hover
    } //_white
    @at-root #{&}_black{
      color: $color-black !important;

      &:hover{
        color: $color-black !important;
      } //hover
    } //_black
  } //_text

  @at-root #{&}_pull-down{
    @at-root #{&}_50{
      margin-top: 50px;
    }
  } //_pull-down

  @at-root #{&}_length{
    @at-root #{&}_long{
      padding-#{$left}: $j-btn-long-padding;
      padding-#{$right}: $j-btn-long-padding;

      &:active{
        padding-#{$left}: $j-btn-long-padding + 1px;
        padding-#{$right}: $j-btn-long-padding - 1px;
      } //active
    } //_long
  } //_length
  @at-root #{&}_width{
    @at-root #{&}_wide{
      padding-top: $j-btn-wide-padding;
      padding-bottom: $j-btn-wide-padding - 3px;
    } //_wide
    @at-root #{&}_narrow{
      padding-top: $j-btn-narrow-padding;
      padding-bottom: $j-btn-narrow-padding;

      &:active{
        padding-top: $j-btn-narrow-padding + 1px;
        padding-bottom: $j-btn-narrow-padding - 1px;
      } //active
    } //_narrow
  } //_width
} //j-btn

.j-tabs{
  @at-root #{&}__title-ul{
    margin: $tab-title-head-sapce $tab-title-h-margin $tab-title-foot-space;
  } //__title-ul
  @at-root #{&}__title-li{
    font-weight: $tab-title-weight;
    color: $tab-title-color;
    margin-#{$left}: $tab-title-left-space /2;
    padding-#{$left}: $tab-title-left-space /2;
    display: inline-block;
    cursor: pointer;
    position: relative;

    @at-root #{&}_active{
      font-weight: $tab-title-active-weight;
      color: $tab-title-active-color;
      cursor: default;
    } //_active

    @at-root #{&}_separator{
      &:after{
        content: $tab-title-separator-char;
        position: absolute;
        #{$left}: $tab-title-separator-offset;
        font-weight: $tab-title-separator-weight;
        top: 0;
      } //after
    } //_separator
  } //__title-li

  @at-root #{&}__content{
     height: $tab-content-height;
     width: $tab-content-width;
  } //__content

  @at-root #{&}__text{
    padding-top: $tab-text-head-space;
    position: absolute;
    width: 100%;
    padding-#{$left}: $tab-text-left-space;
    padding-#{$right}: $tab-text-right-space;
    opacity: 0;
    transition: $tab-text-speed;
    z-index: 1;

    p{
      color: $tab-text-color;
      font-size: $tab-text-size;
      line-height: $tab-text-height;
    }

    @at-root #{&}_active{
      opacity: 1;
      z-index: 5;
    } //_active
  } //__text
} //j-tabs

.slider-buttons{
  position: absolute;
  width: 105%;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);

  @at-root #{&}__button{
    width: 20px;
    height: 20px;
    border: solid 3px black;
    border-left-color: transparent;
    border-bottom-color: transparent;
    position: absolute;
    background-color: transparent;
    outline: none;

    @at-root #{&}_left{
      transform: translateY(-50%) rotate(225deg);
      #{$left}: 0;
    } //_left

    @at-root #{&}_right{
      transform: translateY(-50%) rotate(45deg);
      #{$right}: 0;
    } //_right
  } //__button
} //slider-buttons

.separator{
  margin: $separator-v-space auto;
  width: $separator-length;
  height: $separator-line-width;

  @at-root #{&}_color{
    @at-root #{&}_orange{
      background-color: $color-orange;
    } //_orange
  } //_color
} //separator

.img-effect{
  @at-root #{&}_zoom{
    background-position: center;
    background-size: $effect-bg-size-v;
    background-repeat: no-repeat;
    display: inline-block;
    transition: $effect-transition-medium;
    width: 100%;

    &:hover{
      background-size: $effect-bg-final-size;
    } //hover

    &>img{
      opacity: 0;
    } //img
    @at-root #{&}_inverted{
      background-size: 100% auto;

      &:hover{
        background-size: 104% auto;
      }
    } //_inverted
  } //_zoom
  @at-root #{&}_zoom-new{
    height: 400px;
    overflow: hidden;
    display: block;
    position: relative;
    img{
      max-width: unset;
      max-height: unset;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      transform: scale(1);
      transition: .3s;
    } //img
    &:hover{
      img{
        transform: scale(1.1);
      } //img
    } //hover
  } //_zoom-new
} //img-effect

.gallery-frame{
  img{
    display: none;
  }
}
