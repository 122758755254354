//general layout
//colors
$color-black: #000;
$color-white: #fff;
$color-grey: #959595;
$color-silver: #f0f0f0;
$color-steel-grey: #544d4d;
$color-light-grey: #f7f6f4;

$color-blood-red: #a41010;
$color-orange: #fbab18;
$color-light-blue: #00aeef;
$color-water-blue: #dee1e8;
$color-deep-blue: #0d4159; //#133c59
$color-navy-blue: #00202e;
$color-lime: #96ce4d;

$color-shadow-black: rgba(0,0,0,.2);
$color-shadow-black-opac: rgba(0,0,0,0.5);


//header variables
$general-header-height: 180px;

$header-secondary-color: #1362b0;
$header-sec-separator-margin: 0 10px;
$header-sec-spacing-l: 50px;

$header-font-size-medium: 17px;
$header-font-size-small: 16px;
$header-font-size-big: 18px;
$header-font-color: $color-white;

$header-menu-spaces: 40px;
$header-menu-space-reduced: 30px;
$header-menu-spacing-top: 45px;
$header-menu-items-v-padding: 5px;
$Header-menu-hover-color: $color-light-grey;

$header-selected-border-type: solid;
$header-selected-border-size: 4px;
$header-selected-border-color: $color-blood-red;

$header-sub-padding-v: 20px;
$header-sub-padding-h: 20px;
$header-sub-distance: 30px;
$header-sub-width: 790px;
$header-sub-line-height: 26px;
$header-sub-offset-h: 0px;
$header-sub-shadow-v-h-s: 0px 10px 15px;
$header-sub-column-width: 180px;

$header-menu-shadow-x: 0;
$header-menu-shadow-y: 0;
$header-menu-shadow-size: 13px;
$header-menu-shadow-color: $color-shadow-black-opac;

$header-menu-spacing-top-scrolling: 0px;

//footer variables
$footer-padding: 35px;
$footer-min-height: 400px;
$footer-bg-color: $color-black;

$footer-title-color: $color-silver;
$footer-title-size: 23px;
$footer-link-color: #fff;
$footer-link-size: 15px;
$footer-link-height: 24px;

$footer-adress-color: #fff;
$footer-address-size: 18px;

$social-size: 30px;
$social-color: #fff;
$social-spacing: 20px;
